var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableFiltered',{attrs:{"headers":_vm.mainHeaders,"items":[_vm.expanded],"expanded":_vm.hadToBeHere,"hide-default-footer":""},on:{"update:expanded":function($event){_vm.hadToBeHere=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm.expanded.name)+" ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk i medlemsorganisasjoner","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.canUpsert)?_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Ny medlemsorganisasjon","icon":"mdi-plus"},on:{"handleClick":function($event){return _vm.$emit('new')}}}):_vm._e()]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('BaseTableFiltered',{attrs:{"headers":_vm.subHeaders,"items":_vm.expanded.children,"search":_vm.search},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.$emit('display', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("vis kursområde")])]),(_vm.canUpsert)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.$emit('edit', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger kursområde")])]):_vm._e()]}}],null,true)})],1)]}},{key:"actions",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.$emit('close')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-arrow-left ")])]}}],null,true)},[_c('span',[_vm._v("Tilbake")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }