






















































































import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { defineComponent } from "@vue/composition-api";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
export default defineComponent({
  name: "SubjectRegisterModal",
  components: { BaseModalForm },
  props: {
    type: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    subCourseArea: false,
    categoryType: null,
    area: [
      {
        text: "Hovedområde",
        value: false,
      },
      {
        text: "Kursområde",
        value: true,
      },
    ],
    ssbCodes: ["Kodene er ikke hentet"],
    // Data values
    data: {
      parentId: "",
      name: "",
      code: 0,
      ssbCode: undefined,
      isActive: true,
    },
  }),
  computed: {
    ...mapGetters("ssb", ["getSSBCodes"]),
    ...mapGetters("subjectRegister", ["getSubjectRegisterEntry", "getSubjectRegister"]),
    ...mapFields("subjectRegister", [
      "subjectRegisterEntry.parentId",
      "subjectRegisterEntry.description",
      "subjectRegisterEntry.code",
      "subjectRegisterEntry.ssbCode",
      "subjectRegisterEntry.isActive",
    ]),
    readonly() {
      if ((this as any).type === ModalType.Display) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("subjectRegister", ["addsubjReg", "editsubjReg"]),
    ...mapActions("ssb", ["fetchSSBSubject"]),
    changeType() {
      if ((this as any).categoryType) {
        return ((this as any).subCourseArea = true);
      }
      (this as any).subCourseArea = false;
    },
    setData() {
      (this as any).data = {
        id: (this as any).getSubjectRegisterEntry.id || undefined,
        parentId: (this as any).getSubjectRegisterEntry.parentId || "",
        name: (this as any).getSubjectRegisterEntry.description || "",
        code: (this as any).getSubjectRegisterEntry.code || 0,
        ssbCode: (this as any).getSubjectRegisterEntry.ssbCode || undefined,
        isActive: (this as any).getSubjectRegisterEntry.isActive || true,
      };
    },
    async handleSubmit() {
      if ((this as any).$refs.connectForm.validate()) {
        (this as any).$store.commit("subjectRegister/SET_SUBJECT", this.data);
        if ((this as any).type === ModalType.Add) {
          (this as any).addsubjReg();
          (this as any).$emit("close");
        } else if ((this as any).type === ModalType.Edit) {
          (this as any).editsubjReg();
          (this as any).$emit("close");
        } else {
          openNotification(this.$store, NotificationItemType.Error, "Det skjedde en feil");
        }
      }
    },
  },
  async mounted() {
    (this as any).getSubjectRegisterEntry.parentId
      ? (((this as any).subCourseArea = true), ((this as any).categoryType = true))
      : (((this as any).subCourseArea = false), ((this as any).categoryType = false));
    await (this as any).fetchSSBSubject();
    (this as any).setData();
  },
});
