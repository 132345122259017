



















































import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { defineComponent } from "@vue/composition-api";
import { VSwitch } from "vuetify/lib";
import { mapGetters } from "vuex";

const initialData = () => ({
  search: "",
  expanded: [],
});

export default defineComponent({
  name: "MainCourseTable",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
  },
  props: {
    canUpsert: {
      type: Boolean,
      default: false,
    },
  },
  data: () => initialData(),
  computed: {
    ...mapGetters("subjectRegister", ["getSubjectRegister"]),
    headers() {
      return [
        { text: "", value: "id" },
        { text: "Internkode", value: "code" },
        { text: "Hovedområde", value: "description" },
        { text: "Status", value: "isActive" },
        { text: "Handlinger", value: "actions" },
      ];
    },
    filter() {
      return [
        {
          component: VSwitch,
          value: "isActive",
          staticClass: "mx-3",
          default: true,
          attrs: {
            label: "Vis inaktive hovedområder",
            inset: true,
          },
          apply: (value: any, model: any) => model || value,
        },
      ];
    },
  },
});
